import {
    Column,
    DataSheetGridRef,
    DynamicDataSheetGrid,
    SimpleColumn,
} from 'react-datasheet-grid';
import {Dispatch, SetStateAction, useRef} from 'react';
import {Operation} from 'react-datasheet-grid/dist/types';
import * as styles from './dataGrid.module.css';
import 'react-datasheet-grid/dist/style.css';
import {
    DirtyField,
    MassEditBackendErrors,
    MassEditBackendRelatedError,
    MassEditBackendRowError,
    PropertyValidation,
} from '../../../interfaces/general';
import {MassEditVideoInformation} from '../../../interfaces/videoMassEdit';

export type DataGridValue = string | boolean | null;

export type DataGridRow = Record<string, DataGridValue>;

const getDirtyFieldsInArrayOfObjects = <T extends DataGridRow>(
    array: T[],
    originalArray: T[],
    dirtyFields: DirtyField<T>[],
    setDirtyFields: Dispatch<SetStateAction<DirtyField<T>[]>>,
    propertyValidation: PropertyValidation<T>,
    backendErrors: MassEditBackendErrors<T>,
    setBackendErrors: Dispatch<SetStateAction<MassEditBackendErrors<T>>>,
    idIndexMap: Record<string, number>,
    operations: Operation[],
) => {
    const newDirtyFields = JSON.parse(JSON.stringify(dirtyFields));
    const newBackendErrors = JSON.parse(JSON.stringify(backendErrors));

    operations.forEach(operation => {
        for (let index = operation.fromRowIndex; index < operation.toRowIndex; index++) {
            const dirtyFieldsRow = {} as DirtyField<T>;
            const obj = array[index];

            Object.keys(obj).forEach((key: keyof T) => {
                if (obj[key] !== originalArray[index][key]) {
                    const validity = propertyValidation({property: key, value: obj[key]});

                    dirtyFieldsRow[key] = {
                        ...validity,
                        value: obj[key],
                    };
                }
            });

            newDirtyFields[index] = dirtyFieldsRow;

            const rowId = originalArray[index].id as string;

            if (newBackendErrors.errors[rowId] !== undefined && newBackendErrors.errors[rowId].length > 0) {
                for (let i = newBackendErrors.errors[rowId].length - 1; i >= 0; i--) {
                    const error = newBackendErrors.errors[rowId][i];

                    if (error.value === array[idIndexMap[rowId]][error.field]) {
                        return;
                    }

                    newBackendErrors.errors[rowId].splice(i, 1);

                    if (!error.relatedId) {
                        return;
                    }

                    const relIndex = newBackendErrors.relatedIds[error.relatedId].findIndex(
                        (item: MassEditBackendRelatedError<MassEditVideoInformation>) => item.field === error.field,
                    );

                    if (relIndex !== -1) {
                        newBackendErrors.relatedIds[error.relatedId].splice(relIndex, 1);
                    }
                }
            }

            if (newBackendErrors.relatedIds[rowId] !== undefined && newBackendErrors.relatedIds[rowId].length > 0) {
                for (let i = newBackendErrors.relatedIds[rowId].length - 1; i >= 0; i--) {
                    const errorRelation = newBackendErrors.relatedIds[rowId][i];

                    if (errorRelation.value === array[idIndexMap[rowId]][errorRelation.field]) {
                        return;
                    }

                    newBackendErrors.relatedIds[rowId].splice(i, 1);

                    const relIndex = newBackendErrors.errors[errorRelation.id].findIndex(
                        (item: MassEditBackendRowError<MassEditVideoInformation>) => item.field === errorRelation.field,
                    );

                    if (relIndex !== -1) {
                        newBackendErrors.errors[errorRelation.id].splice(relIndex, 1);
                    }
                }
            }
        }
    });

    setDirtyFields(newDirtyFields);
    setBackendErrors(newBackendErrors);
};

interface DataGridProps<T extends DataGridRow> {
    data: T[],
    originalData: T[],
    title?: string,
    columns: Column<T>[],
    onChange: (values: T[]) => void,
    settings: {
        rowHeight: number,
        rowCount: number,
        gutterColumn?: SimpleColumn<T, keyof T> | false,
    },
    dirtyFields: DirtyField<T>[],
    setDirtyFields: Dispatch<SetStateAction<DirtyField<T>[]>>,
    backendErrors: MassEditBackendErrors<T>,
    setBackendErrors: Dispatch<SetStateAction<MassEditBackendErrors<T>>>,
    updatedCells: Record<string, number>,
    errorCells: Record<string, number>,
    idIndexMap: Record<string, number>,
    setErrorMessage: Dispatch<SetStateAction<string>>,
    propertyValidation: PropertyValidation<T>,
}

const Datagrid = <T extends DataGridRow>({
    data,
    originalData,
    title,
    onChange,
    columns,
    settings: {
        rowHeight,
        rowCount,
        gutterColumn,
    },
    dirtyFields,
    setDirtyFields,
    backendErrors,
    setBackendErrors,
    updatedCells,
    errorCells,
    idIndexMap,
    setErrorMessage,
    propertyValidation,
}: DataGridProps<T>) => {
    const ref = useRef<DataSheetGridRef>(null);

    return (
        <div className={styles.wrapper}>
            {title && (
                <div className={styles.header}>
                    {title}
                </div>
            )}
            <DynamicDataSheetGrid
                ref={ref}
                className={styles.grid}
                rowClassName={({rowData}) => {
                    if (errorCells && errorCells[rowData.id as string] > 0) {
                        return 'datagrid-row__error';
                    }

                    if (updatedCells && updatedCells[rowData.id as string] > 0) {
                        return 'datagrid-row__edited';
                    }

                    return 'datagrid-row';
                }}
                value={data}
                onChange={(newValues, operations) => {
                    onChange(newValues);
                    getDirtyFieldsInArrayOfObjects<T>(
                        newValues,
                        originalData,
                        dirtyFields,
                        setDirtyFields,
                        propertyValidation,
                        backendErrors,
                        setBackendErrors,
                        idIndexMap,
                        operations,
                    );
                }}
                onActiveCellChange={({cell}) => {
                    if (cell === null || cell.colId === undefined) {
                        setErrorMessage('');

                        return;
                    }

                    if (dirtyFields[cell.row] !== undefined
                        && dirtyFields[cell.row][cell.colId] !== undefined
                        && dirtyFields[cell.row][cell.colId].isValid === false
                    ) {
                        setErrorMessage(dirtyFields[cell.row][cell.colId].message);

                        return;
                    }

                    const backendError = backendErrors.errors[originalData[cell.row].id as string]?.find(item => item.field === cell.colId);

                    if (backendError !== undefined) {
                        setErrorMessage(backendError.message);

                        return;
                    }

                    const relatedIdArray = backendErrors.relatedIds[originalData[cell.row].id as string];

                    if (relatedIdArray !== undefined && relatedIdArray.some(item => item.field === cell.colId)) {
                        setErrorMessage('Another field is in conflict with this value.');

                        return;
                    }

                    setErrorMessage('');
                }}
                columns={columns}
                height={rowHeight * rowCount}
                rowHeight={rowHeight}
                gutterColumn={gutterColumn}
                lockRows
                disableExpandSelection
            />
        </div>
    );
};

export default Datagrid;
